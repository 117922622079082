import NvWebsocket from './websocket/WebSocket';
import NvWebRTCOutboundStream from './webrtc/google/OutboundStream';
import NvWebrtcInboundStream from './webrtc/google/InboundStream';
import { generateUUID } from '../utils/misc';
import { logInfo, logError } from '../utils/logger';

const DEFAULT_AVATAR_VIDEO_ELEMENT_ID = 'tokkio-avatar-stream';
const DEFAULT_WEBSOCKET_ENDPOINT = `${
    window.location.protocol === 'https:' ? 'wss:' : 'ws:'
}//${window.location.host}/vms/ws`;

// Define the configuration interface
interface AppConfig {
    avatarVideoElementId: string;
    webcamVideoElementId?: string;
    connectionId?: string;
    queryParams?: string;
    enableWebsocketPing?: boolean;
    enableDummyUDPCall?: boolean;
    websocketPingInterval?: number;
    vstWebsocketEndpoint: string;
    enableLogs?: boolean;
    enableMicrophone?: boolean;
    enableCamera?: boolean;
    websocketTimeoutMS?: number;
    sendCustomWebsocketMessage?: (msg: string) => boolean;
    firstFrameReceivedCallback?: () => void;
    errorCallback?: () => void;
    successCallback?: () => void;
    closeCallback?: () => void;
}

// Facade class
export default class StreamManager {
    private websocket: NvWebsocket | null = null;
    private outboundStream: NvWebRTCOutboundStream | null = null;
    private inboundStream: NvWebrtcInboundStream | null = null;
    private timerInterval: NodeJS.Timeout | null = null;
    private webSocketPingInterval: NodeJS.Timeout | null = null;
    private errorCallbackFired: boolean = false;
    private closeCallbackFired: boolean = false;
    private outboundStreamPeerId: string | null = null;
    private publicIPAddress: string | null = null;
    private inboundStreamPeerId: string | null = null;
    private isInboundConnectionSuccess: boolean = false;
    private isOutboundConnectionSuccess: boolean = false;
    private processing: boolean = false;
    private config: AppConfig;

    constructor() {
        this.config = StreamManager.getDefaultConfig();
    }

    private static getDefaultConfig(): AppConfig {
        return {
            avatarVideoElementId: DEFAULT_AVATAR_VIDEO_ELEMENT_ID,
            webcamVideoElementId: undefined,
            connectionId: undefined,
            queryParams: '',
            enableWebsocketPing: true,
            enableDummyUDPCall: false,
            websocketPingInterval: 2000,
            vstWebsocketEndpoint: DEFAULT_WEBSOCKET_ENDPOINT,
            enableLogs: true,
            enableMicrophone: true,
            enableCamera: true,
            websocketTimeoutMS: 5000,
            errorCallback: () => {},
            successCallback: () => {},
            firstFrameReceivedCallback: () => {},
        };
    }

    public getConfig(): AppConfig {
        return { ...this.config };
    }

    public sendCustomWebsocketMessage(msg: string): boolean {
        if (this.websocket) {
            this.websocket.sendMessage(msg);
            return true;
        }
        return false;
    }

    public toggleMicrophone(): boolean | undefined {
        if (this.outboundStream) {
            return this.outboundStream.toggleMicrophone();
        }
        return undefined;
    }

    public setPublicIPAddress(ipAddress: string): void {
        this.publicIPAddress = ipAddress;
    }

    public getPublicIPAddress(): string | null {
        return this.publicIPAddress;
    }

    public getVSTWebSocketEndpoint(): string {
        return this.config.vstWebsocketEndpoint;
    }

    private unmuteVideoPlayer(): void {
        const videoElement = document.getElementById(
            this.config.avatarVideoElementId || ''
        );
        if (videoElement) {
            logError(this, 'Unmute success');
            (videoElement as HTMLVideoElement).muted = false;
        } else {
            logError(this, 'Failed to unmute, video element not found');
        }
    }

    public updateConfig(newConfig: Partial<AppConfig>): void {
        this.config = {
            ...this.config,
            ...newConfig,
        };
        logInfo(this, 'Updated config: ', this.config);
    }

    public startStreaming() {
        this.errorCallbackFired = true;
        this.closeCallbackFired = true;
        if (this.processing) {
            logError(this, 'A request is already in progress');
            this.notifyError();
            return;
        }
        this.processing = true;
        logInfo(this, 'new streaming process started');
        this.unmuteVideoPlayer();
        this.outboundStreamPeerId = this.config.connectionId || generateUUID();
        // Always print connection ID for tracking and debug purpose
        console.log('Connection ID: ', this.outboundStreamPeerId);
        this.inboundStreamPeerId = `${this.outboundStreamPeerId}_1`;
        this.websocket = new NvWebsocket(
            this,
            this.outboundStreamPeerId,
            this.config.queryParams
        );
    }

    public stopStreaming() {
        logInfo(this, 'Called stop streaming');
        this.handleAppCleanup();
    }

    public onInboundStreamConnection(): void {
        logInfo(
            this,
            'Inbound stream connected, starting outbound stream connection'
        );
        if (this.outboundStreamPeerId) {
            // In NVCF tokkio use-case parallelism is used. In Tokkio it's not the case
            // this.outboundStream = new NvWebRTCOutboundStream(this.outboundStreamPeerId);
        } else {
            logError(this, 'Outbound peer ID not generated');
        }
    }

    public onWebSocketConnected(): void {
        // this.updateRAGStatus();
        if (this.inboundStreamPeerId) {
            Promise.all([
                new Promise<void>((resolve) => {
                    this.inboundStream = new NvWebrtcInboundStream(
                        this,
                        this.inboundStreamPeerId as string
                    );
                    resolve();
                }),
                new Promise<void>((resolve) => {
                    if (
                        this.config.enableCamera ||
                        this.config.enableMicrophone
                    ) {
                        this.outboundStream = new NvWebRTCOutboundStream(
                            this,
                            this.outboundStreamPeerId as string
                        );
                    }
                    resolve();
                }),
            ])
                .then(() => {
                    logInfo(this, 'Both streams have been created');
                    // Any code that depends on both streams being initialized can go here
                })
                .catch((error) => {
                    logError(this, 'Error creating streams:', error);
                });
            if (this.config.enableWebsocketPing) {
                this.startWebSocketPing();
            }
            if (this.config.enableDummyUDPCall) {
                this.handleDummyUDPCall();
            }
        } else {
            logError(this, 'Outbound peer ID not generated');
        }
    }

    private startWebSocketPing(): void {
        this.webSocketPingInterval = setInterval(() => {
            const jsonData = {
                apiKey: 'api/v1/streambridge/ping',
            };
            this.websocket?.sendMessage(JSON.stringify(jsonData));
        }, this.config.websocketPingInterval);
    }

    private handleDummyUDPCall(): void {
        logInfo(this, 'Dummy UDP call will be made in 15 seconds');
        if (this.outboundStreamPeerId) {
            setTimeout(() => {
                const dummyJson = {
                    peerid: this.outboundStreamPeerId,
                    apiKey: 'addDummyUdpTrack',
                };
                logInfo(this, 'Sending Dummy UDP call to VST');
                this.websocket?.sendMessage(JSON.stringify(dummyJson));
            }, 10000);
        }
    }

    private notifyError(): void {
        if (this.errorCallbackFired && this.config.errorCallback) {
            this.errorCallbackFired = false;
            logInfo(this, 'Error callback fired');
            this.config.errorCallback();
        }
    }

    private notifySuccess() {
        if (this.config.successCallback) {
            logInfo(this, 'Success callback fired');
            this.config.successCallback();
        }
    }

    public handleWebSocketMessage(message: any): void {
        if (this.inboundStream) {
            this.inboundStream.handleWebSocketMessage(message);
        }
        if (this.outboundStream) {
            this.outboundStream.handleWebSocketMessage(message);
        }
    }

    public sendWebSocketMessage(message: string): void {
        if (this.websocket) {
            this.websocket.sendMessage(message);
        }
    }

    public handleInboundStreamError(): void {
        logInfo(this, 'in');
        this.notifyError();
    }

    public handleOutboundStreamError(): void {
        logInfo(this, 'out');
        this.notifyError();
    }

    public handleWebSocketError(): void {
        logInfo(this, 'web');
        this.notifyError();
    }

    public setInboundStreamConnectionStatus(status: boolean): void {
        this.isInboundConnectionSuccess = status;
        if (this.isOutboundConnectionSuccess) {
            this.notifySuccess();
        }
    }

    public setOutboundStreamConnectionStatus(status: boolean): void {
        this.isOutboundConnectionSuccess = status;
        if (this.isInboundConnectionSuccess) {
            this.notifySuccess();
        }
    }

    public async handleAppCleanup() {
        logInfo(this, 'Called handle App Cleanup');
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }

        if (this.webSocketPingInterval) {
            clearInterval(this.webSocketPingInterval);
        }

        if (this.inboundStream) {
            this.inboundStream.doCleanup();
        }

        if (this.outboundStream) {
            this.outboundStream.doCleanup();
        }

        if (this.websocket) {
            await this.websocket.close();
        }

        this.resetState();
    }

    private resetState(): void {
        logInfo(this, 'Called reset State');
        this.isInboundConnectionSuccess = false;
        this.isOutboundConnectionSuccess = false;
        this.websocket = null;
        this.inboundStream = null;
        this.outboundStream = null;
        this.outboundStreamPeerId = null;
        this.inboundStreamPeerId = null;
        this.timerInterval = null;
        this.webSocketPingInterval = null;
        this.publicIPAddress = null;
        this.processing = false;
        logInfo(this, 'listening for new processes');
        if (this.closeCallbackFired && this.config.closeCallback) {
            this.closeCallbackFired = false;
            logInfo(this, 'Close callback fired');
            this.config.closeCallback();
        }
    }
}
